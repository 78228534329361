<template>
  <v-app>
    <global-view />

    <global-snackbar />
  </v-app>
</template>

<script>
export default {
  name: "GlobalLayout",

  components: {
    GlobalView: () => import("@/layouts/global/View"),
    GlobalSnackbar: () => import("@/layouts/global/Snackbar"),
  },
};
</script>
